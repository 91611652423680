import {WebSocketSubject} from "rxjs/webSocket";
import {WsMessage} from "../rtbe-connection";
import {ComponentPresenceCallback} from "../types/component-presence-callback";
import {inactiveCode} from "../utils/color-codes";
import {UserPresence} from "../types/user-presence";
import {ComponentPresence} from "../types/component-presence";
import IdleTracker from "idle-tracker";
import {ColorCodeManager} from "./color-code-manager";

export class ComponentPresenceHandler {

    private ws: WebSocketSubject<WsMessage> | null;
    private readonly pageId: string;
    private readonly componentPresenceCallback: ComponentPresenceCallback;
    private componentId: string = '';
    private idleTracker: any;
    private isUserActive = true;
    private messageReceived = false;

    constructor(ws: WebSocketSubject<WsMessage> | null, pageId: string, presenceCallback: ComponentPresenceCallback) {
        this.ws = ws;
        this.pageId = pageId;
        this.componentPresenceCallback = presenceCallback;
        this.idleTracker = new IdleTracker({timeout: 30000, onIdleCallback: this.onIdleCallback});
    }

    resubscribe(ws: WebSocketSubject<WsMessage> | null): void {
        this.ws = ws;
        this.messageReceived = false;
        this.subscribeToComponentPresence();
        this.publishComponentPresence();
    }

    public run(): void {
        this.subscribeToComponentPresence();
        this.publishComponentPresence();
        this.idleTracker.start();
    }

    private subscribeToComponentPresence(): void {
        this.ws?.next(this.getComponentPresencePayload('SUBSCRIBE'));
    }

    private publishComponentPresence(): void {
        if (!this.componentId) {
            return;
        }
        // Subscription creation may take up to 200ms, so wait before publishing presence message
        setTimeout(() => this.ws?.next(this.getComponentPresencePublishPayload()), 200);
        // In rare cases, the subscription creation may take a few seconds, so resend the presence message if no message is received from server
        setTimeout(() => this.republishComponentPresence(), 1000);
    }

    private async republishComponentPresence(): Promise<void> {
        // resend the presence message every one second, up to 5 times, if no message is received from server
        for (let i = 0; i < 5; i++) {
            if (this.messageReceived) {
                break;
            }
            this.ws?.next(this.getComponentPresencePublishPayload());
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    public stop(): void {
        this.ws?.next(this.getComponentPresencePayload('UNSUBSCRIBE'));
        this.idleTracker.end();
        this.componentPresenceCallback([]);
    }

    public async handlePresenceMessage(message: any): Promise<void> {
        this.messageReceived = true;
        const componentPresences: ComponentPresence[] = [];
        const userIds = ComponentPresenceHandler.getUniqueUserIds(message);
        const userColorsMap = ColorCodeManager.get().getComponentPresenceUserColors(userIds);
        for (let eachComponent of message?.components) {
            const componentId = eachComponent.componentId;
            const users: UserPresence[] = [];
            for (let eachUser of eachComponent.users) {
                const userId = eachUser.userId;
                const userName = eachUser.userName;
                users.push({
                    userId,
                    userName,
                    colorCode: eachUser.active ? userColorsMap.get(userId) as string : inactiveCode,
                    active: eachUser.active
                });
            }
            componentPresences.push({
                componentId,
                userPresence: users
            });
        }
        this.componentPresenceCallback(componentPresences);
    }

    public setComponentId(componentId: string): void {
        if (componentId && this.componentId !== componentId) {
            this.componentId = componentId;
            this.publishComponentPresence();
        }
    }

    private onIdleCallback = (payload) => {
        this.isUserActive = !payload.idle;
        if (this.componentId) {
            this.ws?.next(this.getComponentPresencePublishPayload());
        }
    }

    private getComponentPresencePublishPayload(): WsMessage {
        return {
            operation: "PUBLISH",
            objects: [
                {
                    objectCode: "COMPONENT",
                    objectId: this.componentId,
                    parentObjectCode: "PAGE",
                    parentObjectId: this.pageId,
                    operation: "PRESENCE",
                    time: Date.now(),
                    active: this.isUserActive
                }
            ]
        } as WsMessage;
    }

    private getComponentPresencePayload(operation: string): WsMessage {
        return {
            "operation": operation,
            "objects": [
                {
                    "objectCode": "COMPONENT",
                    "filter": {
                        "fieldName": "PAGE",
                        "fieldValue": this.pageId,
                        "operation": "PRESENCE"
                    }
                }
            ]
        } as WsMessage;
    }

    private static getUniqueUserIds(message: any): string[] {
        const userIds: Set<string> = message?.components
                .flatMap(component => component.users)
                .reduce((uniqueUserIds, user) => {
                    uniqueUserIds.add(user.userId);
                    return uniqueUserIds;
                }, new Set<string>());
        return Array.from(userIds);
    }
}