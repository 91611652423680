import {WebSocketSubject} from "rxjs/webSocket";
import {WsMessage} from "../rtbe-connection";
import {PagePresenceCallback} from "../types/page-presence-callback";
import IdleTracker from "idle-tracker";
import {PagePresence} from "../types/page-presence";
import {inactiveCode} from "../utils/color-codes";
import {ColorCodeManager} from "./color-code-manager";

export class PagePresenceHandler {

    private ws: WebSocketSubject<WsMessage> | null;
    private readonly pageId: string;
    private readonly pagePresenceCallback: (activeUsers: PagePresence[]) => void;
    private idleTracker: any;
    private isUserActive = true;
    private messageReceived = false;

    constructor(ws: WebSocketSubject<WsMessage> | null, pageId: string, presenceCallback: PagePresenceCallback) {
        this.ws = ws;
        this.pageId = pageId;
        this.pagePresenceCallback = presenceCallback;
        this.idleTracker = new IdleTracker({timeout: 30000, onIdleCallback: this.onIdleCallback});
    }

    resubscribe(ws: WebSocketSubject<WsMessage> | null): void {
        this.ws = ws;
        this.messageReceived = false;
        this.subscribeToPagePresence();
        this.publishPagePresence();
    }

    public run(): void {
        ColorCodeManager.get().setPagePresenceEnabled(true);
        this.subscribeToPagePresence();
        this.publishPagePresence();
        this.idleTracker.start();
    }

    private subscribeToPagePresence(): void {
        this.ws?.next(this.getPagePresencePayload('SUBSCRIBE'));
    }

    private publishPagePresence(): void {
        // Subscription creation may take up to 200ms, so wait before publishing presence message
        setTimeout(() => this.ws?.next(this.getPagePresencePublishPayload()), 200);
        // In rare cases, the subscription creation may take a few seconds, so resend the presence message if no message is received from server
        setTimeout(() => this.resendPublishMessage(), 1000);
    }

    private async resendPublishMessage(): Promise<void> {
        // resend the presence message every one second, up to 5 times, if no message is received from server
        for (let i = 0; i < 5; i++) {
            if (this.messageReceived) {
                break;
            }
            this.ws?.next(this.getPagePresencePublishPayload());
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    public stop(): void {
        ColorCodeManager.get().setPagePresenceEnabled(false);
        this.ws?.next(this.getPagePresencePayload('UNSUBSCRIBE'));
        this.idleTracker.end();
        this.pagePresenceCallback([]);
    }

    public async handlePresenceMessage(message: any): Promise<void> {
        this.messageReceived = true;
        const presence: PagePresence[] = [];
        const userIds = message?.users
            .map(user => user.userId);
        const userColorsMap = ColorCodeManager.get().getPagePresenceUserColors(userIds);
        for (const eachUser of message?.users) {
            const userId = eachUser.userId;
            const userName = eachUser.userName;
            const active = eachUser.active;
            presence.push({
                userId,
                userName,
                active,
                colorCode: active ? userColorsMap.get(userId) as string : inactiveCode
            });
        }
        this.pagePresenceCallback(presence);
    }

    private onIdleCallback = (payload) => {
        this.isUserActive = !payload.idle;
        this.ws?.next(this.getPagePresencePublishPayload());
    }

    private getPagePresencePublishPayload(): WsMessage {
        return {
            operation: "PUBLISH",
            objects: [
                {
                    objectCode: "PAGE",
                    objectId: this.pageId,
                    operation: "PRESENCE",
                    time: Date.now(),
                    active: this.isUserActive
                }
            ]
        } as WsMessage;
    }

    private getPagePresencePayload(operation: string): WsMessage {
        return {
            "operation": operation,
            "objects": [
                {
                    "objectCode": "PAGE",
                    "objectId": this.pageId,
                    "operation": "PRESENCE"
                }
            ]
        } as WsMessage;
    }
}